<template>
  <div>
    <p class="title">欢迎使用景翔审核管理学习中心~</p>
    <div class="learn">
      <img src="../../assets/images/learn.png" />
      <div class="box">
        <p>
          景翔审核管理系统学习中心<img src="../../assets/images/learn2.png" />
        </p>
        <div class="text">
          <p>考核人：xxx</p>
          <p>所属公司：xxx</p>
          <p>正式业务测评成绩：0</p>
          <p>总学题数：0</p>
          <p>最近业务测评时间：2022.10.2</p>
        </div>
        <div class="btn">
          <p>马上开始</p>
          <div>
            <el-button
              style="
                background-color: #e74c3c;
                color: white;
                padding: 0 20px !important;
              "
              @click="$router.push('/exam')"
              >正在业务测评</el-button
            >
            <el-button
              style="
                background-color: #27ae60;
                color: white;
                padding: 0 20px !important;
              "
              @click="$router.push('/user-test')"
              >学习测试</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.title {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f1f5f2;
  width: 100%;
  padding: 8px 0;
  text-align: center;
  margin: 0;
  color: #548f63;
  border: 1px solid #548f637b;
}
.learn {
  display: flex;
  height: 280px;
  margin-top: 10%;
  margin-left: 10%;
  > img {
    width: 170px;
  }
  .box {
    background-color: white;
    width: 400px;
    padding-left: 30px;
    > p {
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: center;
      padding: 15px 0;
      letter-spacing: 3px;
      img {
        width: 20px;
      }
    }
    .text {
      margin-top: 10px;
      p {
        margin-bottom: 5px;
      }
    }

    .btn {
      margin-top: 30px;
      p {
        margin-bottom: 5px;
      }
    }
  }
}
</style>